import React, { Component } from "react";
import { connect } from "react-redux";
import "./HeaderContent.css";
import BigTitle from "../../Elements/BigTitle";
import Paragraph from "../../Elements/Paragraph";
import LandscapeImg from "../../Elements/Media/LandscapeImg";

class HeaderContent extends Component {
  render() {
    const { data, language } = this.props;
    return (
      <>
        <LandscapeImg image={data.image} />
        <div className="Text-Wrapper">
          <BigTitle text={data.title[language]} />
          <div>
            <Paragraph text={data.textOne[language]} />
            <Paragraph text={data.textTwo[language]} />
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(HeaderContent);
