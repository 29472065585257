import React from 'react';
import './SquareImg.css';


const SquareImg = ({image}) => {
  return (
    <div
      className="SquareImage"
      style={{ backgroundImage: `url(${image})` }}
    ></div>
  );
}

export default SquareImg;