import React from "react";
import { connect } from "react-redux";
import "./SectionFive.css";
import BigTitle from "../../Elements/BigTitle";
import LandscapeImg from "../../Elements/Media/LandscapeImg";

function SectionFive({ data, language }) {
  return (
    <div id="Section5" className="SectionFive">
      <LandscapeImg image={data.imageLandscape} />
      <div className="Text-Wrapper2">
        <BigTitle text={data.title1[language]} />
        <BigTitle text={data.title2[language]} />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(SectionFive);
