import React from "react";
import { connect } from "react-redux";
import "./Footer.css";
import Paragraph from "../Elements/Paragraph";
import SmallTitle from "../Elements/SmallTitle";
import logo from "../../assets/images/logo.svg";

function Footer({ data, language }) {
  return (
    <div id="Section9" className="Footer">
      <div className="Logo-Wrapper">
        <img src={logo} alt="Gorsky Group" className="Logo" />
      </div>
      <div className="Footer-TextSection--One">
        <SmallTitle text={data.title1[language]} />
        <Paragraph text={data.text1[language]} />
        <Paragraph text={data.text2[language]} />
        <Paragraph isTel text={data.text3[language]} />
        <Paragraph isMail text={data.text4[language]} />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(Footer);
