import React from 'react';
import './SpanParagraph.css';


const SpanParagraph = ({text}) => {
  return (
    <p className='Paragraph-Span'>{text}</p>
  );
}

export default SpanParagraph;