import React from "react";
import { connect } from "react-redux";
import "./SectionThree.css";
import LandscapeImg from "../../Elements/Media/LandscapeImg";
import BigTitle from "../../Elements/BigTitle";

const SectionThree = ({ data, language }) => {
  return (
    <div id="Section3" className="SectionThree">
      <LandscapeImg image={data.imageLandscape} />
      <div className="Text-Wrapper2">
        <BigTitle text={data.title1[language]} />
        <BigTitle text={data.title2[language]} />
        <BigTitle text={data.title3[language]} />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(SectionThree);
