import React from "react";
import { connect } from "react-redux";
import "./Header.css";
import Navigation from "./Navigation";
import HeaderContent from "./HeaderContent";
import data from "../../data";

function Header() {
  return (
    <div className="Header">
      <Navigation />
      <HeaderContent data={data.headerContent} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(Header);
