import React from 'react';
import './SmallTitle.css';


function SmallTitle({text}) {
  return (
    <h5 className='SmallTitle'>{text}</h5>
  );
}

export default SmallTitle;