import React, { Component } from "react";
import { connect } from "react-redux";
import "./SectionFour.css";
import SquareImg from "../../Elements/Media/SquareImg";
import BigTitle from "../../Elements/BigTitle";
import Paragraph from "../../Elements/Paragraph";

class SectionFour extends Component {
  render() {
    const { data, language } = this.props;
    return (
      <div id="Section4" className="SectionFour">
        <div className="SectionFour-Element">
          <SquareImg image={data.image1} />
          <div className="SectionFour-TextWrapper">
            <BigTitle text={data.title1[language]} />
            <Paragraph text={data.text1[language]} />
            <Paragraph text={data.text2[language]} />
          </div>
        </div>
        <div className=" SectionFour-Element SectionFour-Element--Right">
          <SquareImg image={data.image2} />
          <div className="SectionFour-TextWrapper">
            <BigTitle text={data.title2[language]} />
            <Paragraph text={data.text3[language]} />
            <Paragraph text={data.text4[language]} />
          </div>
        </div>
        <div className="SectionFour-Element">
          <SquareImg image={data.image3} />
          <div className="SectionFour-TextWrapper">
            <BigTitle text={data.title3[language]} />
            <Paragraph text={data.text5[language]} />
            <Paragraph text={data.text6[language]} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(SectionFour);
