import headerImage from "../assets/images/headerPhoto.jpg";
import sectionTwoImageOne from "../assets/images/sectionTwoPhotoOne.jpg";
import sectionTwoImageTwo from "../assets/images/sectionTwoPhotoTwo.jpg";
import sectionTwoImageThree from "../assets/images/sectionTwoPhotoThree.jpg";
import sectionThreeImageOne from "../assets/images/sectionThreePhotoOne.jpg";
import sectionFourImageOne from "../assets/images/sectionFourPhotoOne.jpg";
import sectionFourImageTwo from "../assets/images/sectionFourPhotoTwo.jpg";
import sectionFourImageThree from "../assets/images/sectionFourPhotoThree.jpg";
import sectionFiveImageOne from "../assets/images/sectionFivePhotoOne.jpg";
import sectionSixImageOne from "../assets/images/sectionSixPhotoOne.jpg";
import sectionSixImageTwo from "../assets/images/sectionSixPhotoTwo.jpg";
import sectionSevenImageOne from "../assets/images/sectionSevenPhotoOne.jpg";
import sectionEightImageOne from "../assets/images/sectionEightPhotoOne.jpg";
import sectionEightImageTwo from "../assets/images/sectionEightPhotoTwo.jpg";

const data = {
  headerContent: {
    title: {
      Srpski: "VRHUNSKI HOTELSKI STANDARDI",
      English: "SUPERIOR STANDARDS"
    },
    textOne: {
      Srpski: "Naš cilj je jasan i jednostavan - razvoj izuzetnih",
      English: "Our goal is clear and simple – to develop exceptional"
    },
    textTwo: {
      Srpski: "projekata u oblasti hotelijerstva.",
      English: "projects in the hotel industry."
    },
    image: headerImage
  },

  sectionOne: {
    text: {
      Srpski:
        "B DEVET je posvećen investicijama, razvoju projekata i upravljanju u sektoru hotelijerstva sa velikim razvojnim portfoliom objekata u urbanim i turističkim centrima.",
      English:
        "B DEVET is focused on investment, project development, and management in the hotel industry, with a development portfolio of hotels in urban and tourist centers."
    }
  },

  sectionTwo: {
    image1: sectionTwoImageOne,
    image2: sectionTwoImageTwo,
    image3: sectionTwoImageThree,
    title1: {
      Srpski: "ŠTA RADIMO",
      English: "What we do"
    },
    title2: {
      Srpski: "КАКО RADIMO",
      English: "How we work"
    },
    title3: {
      Srpski: "GDE INVESTIRAMO",
      English: "Our investments"
    },
    text1: {
      Srpski:
        "Naš cilj je da gradimo i razvijamo vrhunske projekte u oblasti hotelijerstva.",
      English: "Our goal is to develop superior projects in the hotel industry."
    },
    text2: {
      Srpski:
        "Brižljivo smo birali projekte koje ćemo razvijati i fokusirali se na kvalitet.",
      English:
        "We have carefully chosen the projects we will develop and we focused on quality."
    },
    text3: {
      Srpski: "Izabrali smo ključne strateške tačke za razvoj naših projekata.",
      English:
        "We have chosen key strategic points for developing our projects."
    }
  },

  sectionThree: {
    imageLandscape: sectionThreeImageOne,
    title1: {
      Srpski: "INVESTICIJE.",
      English: "Investment."
    },
    title2: {
      Srpski: "RAZVOJ.",
      English: "Development."
    },
    title3: {
      Srpski: "UPRAVLJANJE.",
      English: "Management."
    }
  },

  sectionFour: {
    image1: sectionFourImageOne,
    title1: {
      Srpski: "ODGOVORNO INVESTIRANJE",
      English: "RESPONSIBLE INVESTING"
    },
    text1: {
      Srpski:
        "B DEVET se bavi investiranjem u nove objekte i akvizicijom i uređenjem postojećih hotelskih objekata.",
      English:
        "B DEVET is involved both in investing in new ventures and in revamping the existing hotels."
    },
    text2: {
      Srpski:
        "Objekte druge namene  preuređujemo i pretvaramo u vrhunske hotelske projekte.",
      English:
        "We restore buildings and repurpose them into superior hotel projects."
    },
    image2: sectionFourImageTwo,
    title2: {
      Srpski: "RAZVOJ VRHUNSKIH PROJEKATA",
      English: "DEVELOPMENT OF SUPERIOR PROJECTS"
    },
    text3: {
      Srpski:
        "Dobro poznavanje tržišta u kombinaciji sa modernim dizajnom i konceptom garantuje vrhunsko pozitivno iskustvo za goste hotela.",
      English:
        "Knowing the market, followed by a contemporary design and concept guarantees a positive experience for the hotel guests."
    },
    text4: {
      Srpski:
        "Planiranje, projektovanje i gradnja takvih projekata, kao i selekcija odgovornih izvođača koji će ga sprovesti u delo je takođe deo našeg posla.",
      English:
        "Planning, design and construction of such projects, as well as the selection of responsible contractors that will execute the project is also a part of our job."
    },
    image3: sectionFourImageThree,
    title3: {
      Srpski: "DOBRO I PAMETNO UPRAVLJANJE",
      English: "GOOD AND SMART MANAGEMENT"
    },
    text5: {
      Srpski:
        "Operativno vođenje hotela je jedan od naših glavnih zadataka. Uspešno se bavimo finansijama, administracijom, marketingom hotela kao i edukacijom zaposlenih.",
      English:
        "Managing hotel operations is one of our main tasks. We are successfully handling finance, administration, marketing, as well as the education and development of the employees."
    },
    text6: {
      Srpski:
        "Cilj nam je da kreiramo jedinstveno iskustvo za goste naših hotela i sa druge strane izuzetnu i izazovnu radnu sredinu za naše zaposlene.",
      English:
        "Our goal is to create a unique experience for the guests of our hotels, and exceptional and challenging work environment for our employees."
    }
  },

  sectionFive: {
    imageLandscape: sectionFiveImageOne,
    title1: {
      Srpski: "MULTIDISCIPLINARNI",
      English: "MULTIDISCIPLINARY"
    },
    title2: {
      Srpski: "TIMOVI",
      English: "TEAMS"
    }
  },

  sectionSix: {
    image1: sectionSixImageOne,
    image2: sectionSixImageTwo,
    title1: {
      Srpski: "ETIKA I ESTETIKA",
      English: "ETHICS AND AESTHETICS"
    },
    title2: {
      Srpski: "DETALJI VELIKE SLIKE",
      English: "DETAILS OF LARGE PICTURE"
    },
    text1: {
      Srpski:
        "Uspešan projekat, zadovoljni gosti i odgovoran odnos prema prirodi i sredini u kojoj radimo jedini je način na koji razvijamo svoje projekte, od početka do kraja",
      English:
        "A successful project, satisfied guests and a responsible approach to nature and the work environment is the only way we develop our projects, from the beginning to the end."
    },
    text2: {
      Srpski:
        "Naši timovi su sastavljeni od vrhunskih profesionalaca, različitih specijalizacija, interesovanja i mišljenja koji su u stanju da shvate i savladaju sve izazove.",
      English:
        "Our teams are made up of top professionals who specialize in different fields, with diverse interests and opinions, and the ability to understand and overcome all challenges."
    },
    text3: {
      Srpski: "Svoj način rada smo bazirali na dve osnovne premise:",
      English: "We based our model of operation on two main premises:"
    },
    text4: {
      Srpski:
        "Prva je sposobnost da se vidi šira slika - okruženje, trend i potrebe gostiju.",
      English:
        "The first one is the capacity to see the big picture – the surrounding, the trend and the needs of the guests."
    },
    text41: {
      Srpski:
        "Druga je u apsolutnoj posvećenosti detaljima - od prvog izveštaja i analize, preko planiranja, dizajna i projektovanja, do usluge koja se brižljivo planira i prati iz dana u dan.",
      English:
        "The second one is in our absolute dedication to detail – from the first report and analysis, through planning, design and project development, to the service that is meticulously planned and monitored daily."
    }
  },

  sectionSeven: {
    imageLandscape: sectionSevenImageOne,
    title1: {
      Srpski: "VEČNI GRADOVI KAO INSPIRACIJA",
      English: "ETERNAL CITIES AS AN INSPIRATION"
    }
  },

  sectionEight: {
    image1: sectionEightImageOne,
    image2: sectionEightImageTwo,
    title1: {
      Srpski: "RASKRSNICE PUTEVA",
      English: "INTERSECTIONS"
    },
    title2: {
      Srpski: "U SRCU GRADOVA",
      English: "IN THE HEART OF THE CITIES"
    },
    text1: {
      Srpski:
        "Brižljivo smo birali lokacije za naše Hotele - i gradove i samu poziciju Hotela u njima.",
      English:
        "We have carefully chosen the locations for our hotels – the cities and the position of the hotels within the city."
    },
    text2: {
      Srpski:
        "Naš cilj su bili veliki urbani, turistički, transportni i univerzitetski centri južne Evrope koji se ponose hiljadugodišnjom tradicijom, ali i konstantnom ekspanzijom zahvaljujući izuzetnoj ponudi kulturnih i poslovnih dešavanja.",
      English:
        "Our goals were urban, tourist, transport and university centers in South Europe that are proud of their thousand years old tradition, but also of their continuous growth thanks to an exceptional offer of cultural and business events."
    },
    text3: {
      Srpski:
        "Naši projekti se uobičajeno nalaze nadomak centralnih gradskih trgova, trgovačkih i  poslovnih četvrti velikih gradova.",
      English:
        "Our projects are usually located in big cities, near the town squares, shopping malls, and business centers."
    },
    text4: {
      Srpski:
        "Namera nam je da svojim projektima damo novi pečat ovim sredinama koji je potpuno uklopljen u prepoznatljivu arhitektonsku strukturu gradova.",
      English:
        "Our goal is to give these areas a new imprint that completely fits the recognizable architectural structure of the cities."
    }
  },

  footer: {
    title1: {
      Srpski: "B DEVET DOO",
      English: "B DEVET LTD"
    },
    text1: {
      Srpski: "Milentija Popovića 9",
      English: "9 Milentija Popovica"
    },
    text2: {
      Srpski: "11070 Beograd, Srbija",
      English: "11070 Belgrade, Serbia"
    },
    text3: {
      Srpski: "T. +381 11 4371 000",
      English: "Phone: +381 11 4371 000"
    },
    text4: {
      Srpski: "office@bdevet.rs",
      English: "office@bdevet.rs"
    }
  }
};

export default data;
