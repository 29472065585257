import React from "react";
import "./Paragraph.css";

function Paragraph({ text, isMail }) {
  return !isMail ? (
    <p className="Paragraph-Regular">{text}</p>
  ) : (
    <a href={`mailto:${text}`} className="Paragraph-Regular">
      {text}
    </a>
  );
}

export default Paragraph;
