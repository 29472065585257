import React from "react";
import { connect } from "react-redux";
import "./SectionSeven.css";
import BigTitle from "../../Elements/BigTitle";
import LandscapeImg from "../../Elements/Media/LandscapeImg";

function SectionSeven({ data, language }) {
  return (
    <div id="Section7" className="SectionSeven">
      <LandscapeImg image={data.imageLandscape} />
      <div className="Text-Wrapper2">
        <BigTitle text={data.title1[language]} />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(SectionSeven);
