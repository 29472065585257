import React, { Component } from "react";
import classNames from "classnames";
import animateScrollTo from "animated-scroll-to";
import { connect } from "react-redux";
import "./App.css";
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";
import data from "./data";

class App extends Component {
  state = {
    isButtonVisible: false,
    scrollPosition: 0
  };

  componentDidMount() {
    document.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    this.setState({
      scrollPosition: window.pageYOffset
    });

    if (this.state.scrollPosition > 1000) {
      this.setState({
        isButtonVisible: true
      });
    } else {
      this.setState({
        isButtonVisible: false
      });
    }
  };

  scrollToTop = () => {
    const options = {
      minDuration: 400,
      maxDuration: 600,
      speed: 2000
    };
    animateScrollTo(document.querySelector("#Section10"), options);
  };
  render() {
    const { isButtonVisible } = this.state;
    const backToTopClasses = classNames(
      "BackToTop",
      isButtonVisible && "BackToTop-Visible"
    );

    return (
      <div id="Section10" className="App">
        <Header />
        <Content />
        <Footer data={data.footer} />
        <div className={backToTopClasses} onClick={() => this.scrollToTop()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
          >
            <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm5.247 15l-5.247-6.44-5.263 6.44-.737-.678 6-7.322 6 7.335-.753.665z" />
          </svg>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(App);
