const initialState = {
  language: "Srpski"
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SETTING_LANGUAGE":
      return {
        ...state,
        language: action.data
      };
    default:
      return state;
  }
}
