import React, { Component } from "react";
import "./LandscapeImg.css";

class LandscapeImg extends Component {
  render() {
    const { image } = this.props;
    return (
      <div
        className="LandscapeImage"
        style={{
          backgroundImage: `url(${image}`
        }}
      />
    );
  }
}

export default LandscapeImg;
