import React from "react";
import "./Content.css";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import SectionFive from "./SectionFive";
import SectionSix from "./SectionSix";
import SectionSeven from "./SectionSeven";
import SectionEight from "./SectionEight";
import data from "../../data";

const Content = () => {
  return (
    <div className="Content">
      <SectionOne data={data.sectionOne} />
      <SectionTwo data={data.sectionTwo} />
      <SectionThree data={data.sectionThree} />
      <SectionFour data={data.sectionFour} />
      <SectionFive data={data.sectionFive} />
      <SectionSix data={data.sectionSix} />
      <SectionSeven data={data.sectionSeven} />
      <SectionEight data={data.sectionEight} />
    </div>
  );
};

export default Content;
