import React from "react";
import { connect } from "react-redux";
import "./SectionEight.css";
import SquareImg from "../../Elements/Media/SquareImg";
import BigTitle from "../../Elements/BigTitle";
import Paragraph from "../../Elements/Paragraph";

function SectionEight({ data, language }) {
  return (
    <div id="Section8" className="SectionEight">
      <div className="SectionEight-Element">
        <SquareImg image={data.image1} />
        <div className="SectionEight-TextWrapper">
          <BigTitle text={data.title1[language]} />
          <Paragraph text={data.text1[language]} />
          <Paragraph text={data.text2[language]} />
        </div>
      </div>
      <div className="SectionEight-Element SectionEight-Element--Right">
        <SquareImg image={data.image2} />
        <div className="SectionEight-TextWrapper">
          <BigTitle text={data.title2[language]} />
          <Paragraph text={data.text3[language]} />
          <Paragraph text={data.text4[language]} />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(SectionEight);
