import React, { Component } from "react";
import { connect } from "react-redux";
import { setLanguage } from "../../../../actions/actions";
import animateScrollTo from "animated-scroll-to";
import "./Menu.css";
import classNames from "classnames";

const onama = {
  Srpski: "O nama",
  English: "About us"
};

const timovi = {
  Srpski: "Тimovi",
  English: "Teams"
};

const projekti = {
  Srpski: "Projekti",
  English: "Projects"
};

const kontakt = {
  Srpski: "Kontakt",
  English: "Contact us"
};

class Menu extends Component {
  state = {
    isMenuVisible: false
  };

  toggleMenu = () => {
    const { isMenuVisible } = this.state;
    this.setState({
      isMenuVisible: !isMenuVisible
    });
  };

  scrollToTop = () => {
    const scrollSection4 = document.querySelector("html");
    scrollSection4.scrollIntoView(false);
  };

  render() {
    const { language } = this.props;
    const { isMenuVisible } = this.state;
    const burgerClasses = classNames(
      "Burger",
      isMenuVisible && "Burger-Active"
    );

    const menuClasses = classNames(
      "Menu-List",
      isMenuVisible && "Menu-List-Active"
    );

    const languageButtonClasses = classNames(
      "ButtonLanguage",
      language === "Srpski" && "ButtonLanguage-Active"
    );

    const languageButtonSerbianClasses = classNames(
      "ButtonLanguage",
      language === "English" && "ButtonLanguage-Active"
    );
    const renderMenu = () => {
      const options = {
        maxDuration: 600,
        minDuration: 400,
        speed: 2000
      };
      return (
        <ul className={menuClasses}>
          <li
            onClick={() => {
              this.toggleMenu();
              animateScrollTo(document.querySelector("#Section3"), options);
            }}
          >
            {onama[language]}
          </li>
          <li
            onClick={() => {
              this.toggleMenu();
              animateScrollTo(document.querySelector("#Section5"), options);
            }}
          >
            {timovi[language]}
          </li>
          <li
            onClick={() => {
              this.toggleMenu();
              animateScrollTo(document.querySelector("#Section7"), options);
            }}
          >
            {projekti[language]}
          </li>
          <li
            onClick={() => {
              this.toggleMenu();
              animateScrollTo(
                document.querySelector("body").scrollHeight,
                options
              );
            }}
          >
            {kontakt[language]}
          </li>
        </ul>
      );
    };
    return (
      <div className="Menu">
        <div className="Navigation-Right--Content">
          <div className="Languages">
            <button
              className={languageButtonClasses}
              onClick={() => this.props.dispatch(setLanguage("English"))}
            >
              EN
            </button>
            <p className="LanguageSplitter">/</p>
            <button
              className={languageButtonSerbianClasses}
              onClick={() => this.props.dispatch(setLanguage("Srpski"))}
            >
              SR
            </button>
          </div>
          <div onClick={this.toggleMenu} className={burgerClasses}>
            <span className="BurgerSpan BurgerSpan-top" />
            <span className="BurgerSpan BurgerSpan-center" />
            <span className="BurgerSpan BurgerSpan-bottom" />
          </div>
        </div>
        {renderMenu()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(Menu);
