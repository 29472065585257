import React, { Component } from "react";
import classNames from "classnames";
import "./Navigation.css";
import Menu from "./Menu";
import logo from "../../../assets/images/logo.svg";

class Navigation extends Component {
  state = {
    coloredBackground: false,
    menuInvisible: false,
    scrollPosition: 0
  };

  componentDidMount() {
    document.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    this.setState({
      scrollPosition: window.pageYOffset
    });

    if (this.state.scrollPosition > 200) {
      this.setState({
        coloredBackground: true
      });
    } else {
      this.setState({
        coloredBackground: false
      });
    }
    if (this.state.scrollPosition > 1000) {
      this.setState({
        menuInvisible: true
      });
    } else {
      this.setState({
        menuInvisible: false
      });
    }
  };

  render() {
    const { coloredBackground, menuInvisible } = this.state;
    const navigationClasses = classNames(
      "Navigation-Wrapper",
      coloredBackground && "Navigation-Wrapper--Colored",
      menuInvisible && "Navigation-Wrapper--Invisible"
    );
    return (
      <div className={navigationClasses}>
        <nav className="Navigation">
          <div className="LogoWrapper">
            <img src={logo} alt="Gorsky Group" className="Logo" />
          </div>
          <Menu />
        </nav>
      </div>
    );
  }
}

export default Navigation;
