import React from 'react';
import './BigTitle.css';


function BigTitle({text}) {
  return (
    <h3 className='BigTitle'>{text}</h3>
  );
}

export default BigTitle;