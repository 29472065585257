import React from "react";
import { connect } from "react-redux";
import "./SectionOne.css";
import SpanParagraph from "../../Elements/SpanParagraph";

const SectionOne = ({ data, language }) => {
  return (
    <div id="Section1" className="SectionOne">
      <SpanParagraph text={data.text[language]} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(SectionOne);
