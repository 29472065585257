import React, { Component } from "react";
import { connect } from "react-redux";
import "./SectionTwo.css";
import SquareImg from "../../Elements/Media/SquareImg";
import SmallTitle from "../../Elements/SmallTitle";
import Paragraph from "../../Elements/Paragraph";
import animateScrollTo from "animated-scroll-to";

class SectionTwo extends Component {
  scrollToTop = () => {
    const scrollSection1 = document.getElementById("Section3");
    scrollSection1.scrollIntoView(true);
  };

  scrollToTop2 = () => {
    const scrollSection2 = document.getElementById("Section5");
    scrollSection2.scrollIntoView(true);
  };

  scrollToTop3 = () => {
    const scrollSection3 = document.getElementById("Section7");
    scrollSection3.scrollIntoView(true);
  };

  scrollToTop4 = () => {
    const scrollSection4 = document.getElementById("Section9");
    scrollSection4.scrollIntoView(true);
  };
  render() {
    const options = {
      maxDuration: 600,
      minDuration: 400,
      speed: 2000
    };
    const { data, language } = this.props;
    return (
      <div id="Section2" className="SectionTwo">
        <div
          onClick={() =>
            animateScrollTo(document.querySelector("#Section3"), options)
          }
          className="SectionElement"
        >
          <SquareImg image={data.image1} />
          <div className="SectionTwo-Text">
            <SmallTitle text={data.title1[language]} />
            <Paragraph text={data.text1[language]} />
          </div>
        </div>
        <div
          onClick={() =>
            animateScrollTo(document.querySelector("#Section5"), options)
          }
          className="SectionElement"
        >
          <SquareImg image={data.image2} />
          <div className="SectionTwo-Text">
            <SmallTitle text={data.title2[language]} />
            <Paragraph text={data.text2[language]} />
          </div>
        </div>
        <div
          onClick={() =>
            animateScrollTo(document.querySelector("#Section7"), options)
          }
          className="SectionElement"
        >
          <SquareImg image={data.image3} />
          <div className="SectionTwo-Text">
            <SmallTitle text={data.title3[language]} />
            <Paragraph text={data.text3[language]} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(SectionTwo);
